import React from 'react';
import PropTypes from 'prop-types';
import CreativeLifecycleReport from 'components/reporting/CreativeLifecycleReport';
import GuidelineReport from 'components/reporting/GuidelineReport';
import ScoreReport from 'components/reporting/ScoreReport';
import {
  attributeLookupsProps,
  customFilterProps,
  dateOptionProps,
  scoreProps,
  guidelineScoreProps,
  guidelinesDetailsProps,
} from 'components/reporting/shared';
import TimePeriodReport from 'components/reporting/TimePeriodReport';
import { useViewPage } from 'utilities/mixpanel';
import { valueIf } from 'utilities/object';

const propTypes = {
  type: PropTypes.string.isRequired,
  attributeLookups: attributeLookupsProps,
  canViewSpend: PropTypes.bool,
  customFilters: PropTypes.arrayOf(customFilterProps),
  customRangeProps: PropTypes.shape({
    customDatesEnabled: PropTypes.bool,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
  }),
  customSegments: PropTypes.arrayOf(PropTypes.string),
  dateOptions: PropTypes.arrayOf(dateOptionProps),
  guidelineScores: PropTypes.arrayOf(guidelineScoreProps),
  guidelinesDetails: PropTypes.arrayOf(guidelinesDetailsProps),
  initialDateOption: dateOptionProps,
  scores: PropTypes.arrayOf(scoreProps),
};

const defaultProps = {
  attributeLookups: {},
  canViewSpend: false,
  customFilters: [],
  customRangeProps: {},
  customSegments: [],
  dateOptions: [],
  guidelineScores: [],
  guidelinesDetails: [],
  initialDateOption: undefined,
  scores: [],
};

function NewReport({
  attributeLookups,
  canViewSpend,
  customFilters,
  customRangeProps,
  customSegments,
  dateOptions,
  initialDateOption,
  scores,
  type,
  guidelineScores,
  guidelinesDetails,
}) {
  useViewPage();
  const renderScoreReport = () => {
    const defaultScoreId = scores.find((score) => score.isDefault)?.versionId;
    const selectedMetric = `scoreRate::${defaultScoreId}`;

    const initialProperties = {
      endDate: initialDateOption.endDate,
      filters: {
        ...(canViewSpend && {
          campaignStatus: [{
            label: 'In-Flight',
            value: 'In-Flight',
          }],
        }),
      },
      metrics: [selectedMetric],
      segments: [canViewSpend && 'campaignStatus'],
      sort: {
        asc: false,
        key: selectedMetric,
      },
      startDate: initialDateOption.startDate,
      vizMetric: selectedMetric,
    };

    return (
      <ScoreReport
        attributeLookups={attributeLookups}
        canViewSpend={canViewSpend}
        customFilters={customFilters}
        customRangeProps={customRangeProps}
        customSegments={customSegments}
        dateOptions={dateOptions}
        initialDescription=""
        initialProperties={initialProperties}
        initialTitle="Untitled Report"
        scores={scores}
        type={type}
      />
    );
  };

  const renderTimePeriodReport = () => {
    const defaultScoreId = scores.find((score) => score.isDefault)?.versionId;
    const selectedMetric = `scoreRate::${defaultScoreId}`;

    const initialProperties = {
      endDate: initialDateOption.endDate,
      filters: {
        ...valueIf(canViewSpend, 'campaignStatus', [{
          label: 'In-Flight',
          value: 'In-Flight',
        }]),
      },
      metrics: [selectedMetric],
      segments: [],
      sort: {
        asc: false,
        key: selectedMetric,
      },
      startDate: initialDateOption.startDate,
      timePeriod: 'month',
      vizMetric: selectedMetric,
    };

    return (
      <TimePeriodReport
        attributeLookups={attributeLookups}
        canViewSpend={canViewSpend}
        customFilters={customFilters}
        customRangeProps={customRangeProps}
        customSegments={customSegments}
        dateOptions={dateOptions}
        initialDescription=""
        initialProperties={initialProperties}
        initialTitle="Untitled Report"
        scores={scores}
        type={type}
      />
    );
  };

  const renderGuidelineReport = () => {
    const selectedMetric = 'adoptionRate';

    const initialProperties = {
      endDate: initialDateOption.endDate,
      filters: {
        ...valueIf(canViewSpend, 'campaignStatus', [{
          label: 'In-Flight',
          value: 'In-Flight',
        }]),
      },
      metrics: [selectedMetric],
      segments: [],
      sort: {
        asc: false,
        key: selectedMetric,
      },
      startDate: initialDateOption.startDate,
      timePeriod: 'month',
      vizMetric: selectedMetric,
    };

    return (
      <GuidelineReport
        attributeLookups={attributeLookups}
        canViewSpend={canViewSpend}
        customFilters={customFilters}
        customRangeProps={customRangeProps}
        customSegments={customSegments}
        dateOptions={dateOptions}
        guidelineScores={guidelineScores}
        guidelinesDetails={guidelinesDetails}
        initialDescription=""
        initialProperties={initialProperties}
        initialTitle="Untitled Report"
        scores={scores}
        type={type}
      />
    );
  };

  const renderCreativeLifecycleReport = () => {
    const initialProperties = {
      endDate: initialDateOption.endDate,
      metrics: ['activationRate'],
      segments: ['market'],
      startDate: initialDateOption.startDate,
      vizMetric: 'activationRate',
    };

    return (
      <CreativeLifecycleReport
        canViewSpend={canViewSpend}
        dateOptions={dateOptions}
        initialDescription=""
        initialProperties={initialProperties}
        initialTitle="Untitled Report"
        type={type}
      />
    );
  };

  switch (type) {
    case 'ScoreReport':
      return renderScoreReport();
    case 'TimePeriodReport':
      return renderTimePeriodReport();
    case 'GuidelineReport':
      return renderGuidelineReport();
    case 'CreativeLifecycleReport':
      return renderCreativeLifecycleReport();
    default:
      return (
        <div>{ `Unknown Report Type: ${type}` }</div>
      );
  }
}

NewReport.propTypes = propTypes;
NewReport.defaultProps = defaultProps;

export default NewReport;
