import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  removeObjectByValue,
} from '@makeably/creativex-design-system';
import { metricTooltips } from 'components/creative_lifecycle/shared';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import BreakdownDrawer from 'components/reporting/BreakdownDrawer';
import ConfigureReport from 'components/reporting/ConfigureReport';
import CoreAssetsFilter from 'components/reporting/CoreAssetsFilter';
import {
  getBinsByTag,
  getItems,
  getPlacementLabel,
} from 'components/reporting/creativeLifecycleUtilities';
import MetricVisualization from 'components/reporting/MetricVisualization';
import ReportTags from 'components/reporting/ReportTags';
import {
  dateOptionProps,
  propertiesProps,
} from 'components/reporting/shared';
import {
  calcPropertiesJson,
  getHeaders,
  parseProperties,
  updateVizMetric,
} from 'components/reporting/utilities';
import { sortObjectArray } from 'utilities/array';
import { saveItemsCsvFile } from 'utilities/file';
import { getObjFilterTest } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { track } from 'utilities/mixpanel';
import { removeProperty } from 'utilities/object';
import { get } from 'utilities/requests';
import {
  coreAssetsReportingReportsPath,
  editReportingReportPath,
} from 'utilities/routes';
import styles from './CreativeLifecycleReport.module.css';

const propTypes = {
  canViewSpend: PropTypes.bool.isRequired,
  dateOptions: PropTypes.arrayOf(dateOptionProps).isRequired,
  initialDescription: PropTypes.string.isRequired,
  initialProperties: propertiesProps.isRequired,
  initialTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string,
};

const defaultProps = {
  uuid: undefined,
};

const segments = [
  {
    label: 'Ad\nFormat',
    value: 'adFormat',
  },
  {
    label: 'Asset\nType',
    value: 'assetType',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Campaign\nName',
    value: 'campaign',
  },
  {
    label: 'Core\nAsset\nID',
    value: 'uuid',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'File\nName',
    value: 'fileName',
  },
  {
    label: 'Market',
    value: 'market',
  },
  {
    label: 'Measurement\nPartner',
    value: 'measurementPartner',
  },
  {
    label: 'Placement',
    value: 'placement',
  },
  {
    label: 'Production\nPartner',
    value: 'partner',
  },
];

const nonSpendMetrics = [
  {
    label: 'Activation\nRate',
    tooltip: metricTooltips.activationRate,
    value: 'activationRate',
  },
  {
    label: 'Ad\nFormats\nActivated',
    tooltip: metricTooltips.adFormatsActivated,
    value: 'adFormatsActivated',
  },
  {
    label: 'Average\nScore',
    tooltip: metricTooltips.averageScore,
    value: 'averageScore',
  },
  {
    label: 'Channels\nActivated',
    tooltip: metricTooltips.channelsActivated,
    value: 'channelsActivated',
  },
  {
    label: 'Core Assets\nActivated',
    tooltip: metricTooltips.assetsActivated,
    value: 'assetsActivated',
  },
  {
    label: 'Core Assets\nNot Activated',
    tooltip: metricTooltips.assetsNotActivated,
    value: 'assetsNotActivated',
  },
  {
    label: 'Core Assets\nUploaded',
    tooltip: metricTooltips.assets,
    value: 'assets',
  },
  {
    label: 'Markets\nActivated',
    tooltip: metricTooltips.marketsActivated,
    value: 'marketsActivated',
  },
  {
    label: 'Placements\nActivated',
    tooltip: metricTooltips.placementsActivated,
    value: 'placementsActivated',
  },
  {
    label: 'Repurposed\nRate',
    tooltip: metricTooltips.repurposedRate,
    value: 'repurposedRate',
  },
  {
    label: 'Reusage\nRate',
    tooltip: metricTooltips.reusageRate,
    value: 'reusageRate',
  },
];

const spendMetrics = [
  {
    label: 'Activated\nImpressions',
    tooltip: metricTooltips.impressions,
    value: 'impressions',
  },
  {
    label: 'Activated\nMedia\nSpend',
    tooltip: metricTooltips.spend,
    value: 'spend',
  },
  {
    label: 'Total\nPosts',
    tooltip: metricTooltips.posts,
    value: 'posts',
  },
];

const combinedMetrics = sortObjectArray([...nonSpendMetrics, ...spendMetrics], 'label');

async function getAssets(dateOption) {
  const params = {
    date_type: dateOption.type,
    start_date: dateOption.startDate,
  };

  return get(coreAssetsReportingReportsPath(params));
}

function preprocessAssets(assets) {
  return assets.map((asset) => ({
    ...asset,
    measurementPartner: asset.measurementPartner || 'N/A',
  }));
}

function filterPosts(
  posts,
  channelSelections,
  marketSelections,
  adFormatSelections,
  placementSelections,
) {
  // @note: This function filters the channels and markets of the posts for a single core asset
  const hasChannelFilter = channelSelections?.length > 0;
  const hasMarketFilter = marketSelections?.length > 0;
  const hasAdFormatFilter = adFormatSelections?.length > 0;
  const hasPlacementFilter = placementSelections?.length > 0;

  if (!hasChannelFilter && !hasMarketFilter && !hasAdFormatFilter && !hasPlacementFilter) {
    // This could be an empty array, for a core asset with no matches
    return posts;
  }

  const hasValue = (opts, value) => opts.findIndex((sel) => sel?.value === value) >= 0;
  const filtered = posts.filter((post) => {
    const [channel, market, adFormat, publisher, placement] = post.split('::');

    if (hasChannelFilter && !hasValue(channelSelections, channel)) return false;
    if (hasMarketFilter && !hasValue(marketSelections, market)) return false;
    if (hasAdFormatFilter && !hasValue(adFormatSelections, adFormat)) return false;
    if (hasPlacementFilter
      && !hasValue(placementSelections, getPlacementLabel(publisher, placement))) return false;
    return true;
  });

  // @note: Returns null if all posts have been filtered out.
  // Core assets with empty posts arrays are only removed if channel or market
  // filters are active. Otherwise, they are left as core assets with no matches
  return (filtered.length > 0 ? filtered : null);
}

function filterCoreAssets(coreAssets, filters) {
  // @note: A core asset can be connected to multiple channel/market/ad format/placement
  // combinations via inflight matches
  // This filters those pairs to only those that match the user's current filters

  // Separate channel, market, ad format, and placement from the other filters
  const {
    channel, market, adFormat, placement, ...rest
  } = filters;
  const filterTest = getObjFilterTest(rest);

  return coreAssets.reduce((all, coreAsset) => {
    // Remove the core asset if it doesn't pass the other filters
    if (!filterTest(coreAsset)) {
      return all;
    }

    // Filter the posts array and remove the core asset's posts if all have been filtered out
    // to ensure "assets not activated" and "cores assets uploaded" are un-impacted by filters
    const posts = filterPosts(coreAsset.posts, channel, market, adFormat, placement);
    if (!posts) {
      return [...all, {
        ...coreAsset,
        posts: [],
      }];
    }

    // Keep the core asset, but with filtered posts
    return [...all, {
      ...coreAsset,
      posts,
    }];
  }, []);
}

function CreativeLifecycleReport({
  canViewSpend,
  dateOptions,
  initialDescription,
  initialProperties,
  initialTitle,
  type,
  uuid,
}) {
  const [selectedDateOption, setSelectedDateOption] = useState();
  const [coreAssets, setCoreAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const processedCoreAssets = useMemo(
    () => preprocessAssets(coreAssets),
    [coreAssets],
  );

  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [vizMetric, setVizMetric] = useState(updateVizMetric(undefined, selectedMetrics));
  const [headers, setHeaders] = useState([]);
  const [items, setItems] = useState([]);
  const [calculating, setCalculating] = useState(true);
  const [sort, setSort] = useState();
  const [userSorted, setUserSorted] = useState(false);
  const [sortedItems, setSortedItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filteredCoreAssets, setFilteredCoreAssets] = useState(processedCoreAssets);
  const [page, setPage] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const filterCount = Object.values(selectedFilters).reduce((total, arr) => total + arr.length, 0);

  const supportedMetrics = canViewSpend ? combinedMetrics : nonSpendMetrics;

  const propertiesJson = calcPropertiesJson({
    selectedDateOption,
    selectedFilters,
    selectedMetrics,
    selectedSegments,
    sort,
    vizMetric,
  });

  const hasChanged = propertiesJson !== JSON.stringify(initialProperties);

  const fetchAssets = async () => {
    setLoading(true);
    const response = await getAssets(selectedDateOption);

    if (response.isError) {
      const isTimeout = response.status === 504;
      const message = isTimeout ? 'The data request has timed out' : 'The data could not be loaded';

      addToast(message, { type: 'error' });
      setCoreAssets([]);
    } else {
      setCoreAssets(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    const parsed = parseProperties(initialProperties, {
      dateOptions,
      metrics: supportedMetrics,
      segments,
    });

    setSelectedDateOption(parsed.selectedDateOption);
    setSelectedFilters(parsed.selectedFilters);
    setSelectedMetrics(parsed.selectedMetrics);
    setSelectedSegments(parsed.selectedSegments);
    setSort(parsed.sort);
    setVizMetric(parsed.vizMetric);
  }, [initialProperties]);

  useEffect(() => {
    fetchAssets();
  }, [selectedDateOption]);

  useEffect(() => {
    setPage(1);
    setFilteredCoreAssets(filterCoreAssets(processedCoreAssets, selectedFilters));
  }, [processedCoreAssets, selectedFilters]);

  useEffect(() => {
    const calculate = async () => {
      setCalculating(true);
      const bins = getBinsByTag(selectedSegments, filteredCoreAssets);
      const allItems = getItems(bins, selectedSegments);

      setPage(1);
      setHeaders(getHeaders(selectedSegments, selectedMetrics, vizMetric, setVizMetric));
      setItems(allItems);
      setSortedItems(allItems);
      setCalculating(false);
    };

    calculate();
  }, [filteredCoreAssets, selectedSegments, selectedMetrics, vizMetric]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      const sorted = items.slice().sort(byKeyDir);
      const indexed = sorted.map((item, index) => ({
        ...item,
        index: { value: index + 1 },
      }));

      setSortedItems(indexed);
    }
  }, [items, sort]);

  useEffect(() => {
    if (vizMetric && !userSorted) {
      setSort({
        asc: false,
        key: vizMetric.value,
      });
    }
  }, [vizMetric, userSorted]);

  const updateSelectedMetrics = (selected) => {
    setVizMetric((metric) => updateVizMetric(metric, selected));
    setSelectedMetrics(selected);
  };

  const removeSelectedSegment = (option) => {
    setSelectedSegments((current) => removeObjectByValue(current, option));
  };

  const removeSelectedMetric = (option) => {
    updateSelectedMetrics(removeObjectByValue(selectedMetrics, option));
  };

  const removeSelectedFilter = (key) => {
    setSelectedFilters((last) => removeProperty(last, key));
  };

  const handleSortChange = (value) => {
    setUserSorted(true);
    setSort(value);
  };

  const getEmptyStateMessage = () => {
    if (loading) {
      return '';
    }
    if (calculating) {
      return 'Calculating metrics';
    }
    if (coreAssets.length === 0) {
      return 'No data to display';
    }
    if (sortedItems.length === 0 && filterCount !== 0) {
      return 'Remove filters to see data';
    }
    return null;
  };

  const renderEmptyState = () => {
    const message = getEmptyStateMessage();

    if (!message) return null;

    return (
      <div className={`t-empty ${styles.empty}`}>
        { message }
      </div>
    );
  };

  const handleSave = (reportUuid) => {
    window.location.href = editReportingReportPath(reportUuid);
  };

  const handleFiltersChange = (filters) => {
    setSelectedFilters(filters);

    track('apply_filter', {
      filters,
      type,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDateOption(date);

    track('apply_date_change', {
      date: date.label,
      type,
    });
  };

  return (
    <>
      <ConfigureReport
        hasChanged={hasChanged}
        initialDescription={initialDescription}
        initialTitle={initialTitle}
        propertiesJson={propertiesJson}
        type={type}
        uuid={uuid}
        onExportCsv={(title) => saveItemsCsvFile(title, sortedItems, headers)}
        onSave={handleSave}
      />
      <Card padding={false}>
        <div className={styles.top}>
          <div className="u-flexRow u-justifyBetween">
            <div className={styles.controlButtons}>
              <Button
                label="Setup"
                variant="secondary"
                onClick={() => setDrawerOpen(true)}
              />
              <CoreAssetsFilter
                coreAssets={processedCoreAssets}
                isOpen={filterOpen}
                segments={segments}
                selections={selectedFilters}
                onClose={() => setFilterOpen(false)}
                onOpen={() => setFilterOpen(true)}
                onSelectionsChange={handleFiltersChange}
              />
            </div>
            <Dropdown
              disabled={loading}
              menuProps={{ size: 'medium' }}
              options={dateOptions}
              selected={selectedDateOption}
              size="medium"
              onChange={handleDateChange}
            />
          </div>
          <ReportTags
            filters={selectedFilters}
            removeFilter={removeSelectedFilter}
            removeSelectedMetric={removeSelectedMetric}
            removeSelectedSegment={removeSelectedSegment}
            segments={segments}
            selectedMetrics={selectedMetrics}
            selectedSegments={selectedSegments}
            setSelectedSegments={setSelectedSegments}
            onFilterClick={() => setFilterOpen(true)}
            onMetricClick={() => setDrawerOpen(true)}
            onSegmentClick={() => setDrawerOpen(true)}
          />
        </div>
        <Divider />
        <MetricVisualization
          displayMetric={vizMetric}
          items={sortedItems}
          loading={loading || calculating}
          selectedMetrics={selectedMetrics}
          selectedSegments={selectedSegments}
        />
        <Divider />
        { renderEmptyState() }
        <div className={styles.table}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={(v) => setPage(v)}
            onSortChange={handleSortChange}
          />
        </div>
      </Card>
      <BreakdownDrawer
        isOpen={drawerOpen}
        metrics={supportedMetrics}
        segments={segments}
        selectedMetrics={selectedMetrics}
        selectedSegments={selectedSegments}
        setSelectedMetrics={updateSelectedMetrics}
        setSelectedSegments={setSelectedSegments}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
}

CreativeLifecycleReport.propTypes = propTypes;
CreativeLifecycleReport.defaultProps = defaultProps;

export default CreativeLifecycleReport;
